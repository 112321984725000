<template>
	<div data-test="container-workflow-badge-list">
		<ul v-if="!isLoading && versionWorkflowStatuses.length > 0" class="pl-3 mt-2" data-test="list-workflow-badges">
			<li v-for="versionWorkflowStatus in versionWorkflowStatuses" :key="versionWorkflowStatus.id">
				<WorkflowStatus :version-workflow-status="versionWorkflowStatus" class="mb-4" :data-test="`badge-workflow-${versionWorkflowStatus.id}`" />
			</li>
		</ul>
		<div data-test="container-progress-bar" class="loader" style="width: 70%">
			<v-progress-linear v-if="isLoading && !isPolling" color="primary" indeterminate :height="2" data-test="progress-loading" />
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import WorkflowManagerModuleGuard from '@/mixins/ModulesGuards/Workflow/WorkflowManagerModuleGuard'
import { useTimeoutPoll } from '@vueuse/core'

export default {
	name: 'WorkflowStatusList',
	components: {
		WorkflowStatus: () => ({
			component: import('@/components/Workflows/WorkflowStatus')
		})
	},
	mixins: [WorkflowManagerModuleGuard],
	props: {
		version: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			isLoading: true,
			pollCount: 0,
			maxPollCount: 5,
			isPolling: false,
			pause: () => {},
			resume: () => {},
			baseDelay: 500 // Base delay in milliseconds
		}
	},
	computed: {
		...mapState({
			document: state => state.documents.selection.current
		}),
		...mapGetters({
			getVersionWorkflowStatuses: 'workflows/versionsWorkflowStatuses'
		}),
		versionWorkflowStatuses() {
			return this.getVersionWorkflowStatuses(this.version.id) ?? []
		},
		isLastVersion() {
			return this.version.id === this.document?.latestVersion?.id
		}
	},
	created() {
		if (this.isLastVersion) {
			this.setupPolling()
		}
		this.loadVersionsWorkflows()
	},
	beforeDestroy() {
		this.pause()
	},
	methods: {
		...mapMutations({
			setVersionsWorkflowsStatuses: 'workflows/setVersionsWorkflowsStatuses'
		}),
		async loadVersionsWorkflows() {
			this.isLoading = true
			try {
				const data = await this.service.getNodeWorkflowStatuses(this.vendorId, this.document.id, {
					node_version_id: this.version.id
				})
				this.setVersionsWorkflowsStatuses({
					statuses: data,
					versionId: this.version.id
				})
				if (data.length === 0 && this.pollCount < this.maxPollCount) {
					if (!this.isPolling) {
						this.pollCount = 0
						this.resume()
					}
				} else {
					this.pause()
				}
			} catch (error) {
				this.pause()
				throw error
			} finally {
				this.isLoading = false
			}
		},
		setupPolling() {
			const { isActive, pause, resume } = useTimeoutPoll(
				async () => {
					if (!this.isLastVersion || this.pollCount >= this.maxPollCount) {
						this.pause()
						return
					}
					await this.loadVersionsWorkflows()
					this.pollCount++
				},
				() => this.baseDelay * (this.pollCount + 1),
				{ immediate: false }
			)

			this.isPolling = isActive
			this.pause = pause
			this.resume = resume
		}
	}
}
</script>

<style scoped>
.loader {
	position: absolute;
}
</style>
